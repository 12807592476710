@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~font-awesome/scss/font-awesome";
@import "./prismjs";
// Variable overrides
$theme-colors: (
    "primary": #871c66,
    "primary-light": #a25189,
    "secondary": #ffcf02,
);

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #f4f6f8;
    height: 100%;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

#root {
    height: 100%;
}

:not(pre)>code {
    font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace;
    background-color: #eeeeee;
    padding: 2px 4px;
    direction: ltr;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    font-size: 14px;
}

#loading {
    background: #f6f6f6 none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: 1;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
    margin: 0 auto;
}

#loading .loading-center {
    position: absolute;
    top: calc(50% - 25px) !important;
    left: calc(50% - 25px) !important;
}

#loading * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

#loading .outer {
    border: 5px solid map-get($theme-colors, "primary");
    opacity: 0.9;
    border-right-color: transparent;
    border-left-color: transparent;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    border-radius: 50% !important;
    -webkit-box-shadow: 0 0 5px map-get($theme-colors, "primary");
    -moz-box-shadow: 0 0 5px map-get($theme-colors, "primary");
    box-shadow: 0 0 5px map-get($theme-colors, "primary");
    -webkit-animation: spin-pulse 1s linear infinite normal;
    -moz-animation: spin-pulse 1s linear infinite normal;
    -ms-animation: spin-pulse 1s linear infinite normal;
    animation: spin-pulse 1s linear infinite normal;
    -webkit-animation-delay: 0;
    -moz-animation-delay: 0;
    -o-animation-delay: 0;
    animation-delay: 0;
}

#loading .inner {
    border: 5px solid map-get($theme-colors, "primary");
    opacity: 0.9;
    border-left-color: transparent;
    border-right-color: transparent;
    border-radius: 50% !important;
    -webkit-box-shadow: 0 0 5px map-get($theme-colors, "primary");
    -moz-box-shadow: 0 0 5px map-get($theme-colors, "primary");
    box-shadow: 0 0 5px map-get($theme-colors, "primary");
    width: 30px;
    height: 30px;
    position: relative;
    top: -50px;
    margin: 0 auto;
    -webkit-animation: spin-right 1s linear infinite normal;
    -moz-animation: spin-right 1s linear infinite normal;
    -ms-animation: spin-right 1s linear infinite normal;
    animation: spin-right 1s linear infinite normal;
    -webkit-animation-delay: 0;
    -moz-animation-delay: 0;
    -o-animation-delay: 0;
    animation-delay: 0;
}

@keyframes spin-right {
    from {
        transform: rotate(0deg);
        opacity: 0.2;
    }

    50% {
        transform: rotate(180deg);
        opacity: 1;
    }

    to {
        transform: rotate(360deg);
        opacity: 0.2;
    }
}

@-moz-keyframes spin-right {
    from {
        -moz-transform: rotate(0deg);
        opacity: 0.2;
    }

    50% {
        -moz-transform: rotate(180deg);
        opacity: 1;
    }

    to {
        -moz-transform: rotate(360deg);
        opacity: 0.2;
    }
}

@-webkit-keyframes spin-right {
    from {
        -webkit-transform: rotate(0deg);
        opacity: 0.2;
    }

    50% {
        -webkit-transform: rotate(180deg);
        opacity: 1;
    }

    to {
        -webkit-transform: rotate(360deg);
        opacity: 0.2;
    }
}

@keyframes spin-pulse {
    from {
        transform: rotate(160deg);
        opacity: 0;
        box-shadow: 0 0 1px map-get($theme-colors, "primary");
    }

    50% {
        transform: rotate(145deg);
        opacity: 1;
    }

    to {
        transform: rotate(-320deg);
        opacity: 0;
    }
}

@-moz-keyframes spin-pulse {
    from {
        -moz-transform: rotate(160deg);
        opacity: 0;
        box-shadow: 0 0 1px map-get($theme-colors, "primary");
    }

    50% {
        -moz-transform: rotate(145deg);
        opacity: 1;
    }

    to {
        -moz-transform: rotate(-320deg);
        opacity: 0;
    }
}

@-webkit-keyframes spin-pulse {
    from {
        -webkit-transform: rotate(160deg);
        opacity: 0;
        box-shadow: 0 0 1px map-get($theme-colors, "primary");
    }

    50% {
        -webkit-transform: rotate(145deg);
        opacity: 1;
    }

    to {
        -webkit-transform: rotate(-320deg);
        opacity: 0;
    }
}

.page-loader,
.page-loader:before,
.page-loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}

.page-loader {
    color: map-get($theme-colors, "primary");
    font-size: 10px;
    margin: 35px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.page-loader:before,
.page-loader:after {
    content: "";
    position: absolute;
    top: 0;
}

.page-loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.page-loader:after {
    left: 3.5em;
}

.group-page-loader,
.group-page-loader:before,
.group-page-loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}

.group-page-loader {
    color: map-get($theme-colors, "primary");
    font-size: 10px;
    left: 25%;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.group-page-loader:before,
.group-page-loader:after {
    content: "";
    position: absolute;
    top: 0;
}

.group-page-loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.group-page-loader:after {
    left: 3.5em;
}

@-webkit-keyframes load7 {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes load7 {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

.ck-editor__editable_inline {
    height: 500px !important;
}

.input-file-box {
    width: 100%;
}

.ReactCrop,
.ReactCrop__image {
    // width: 350px !important;
}

/* .input-file-box {
    display: inline-flex;
} */
.reactCropImageDiv {
    display: inline-block;
    width: 40%;

    @media screen and (max-width: 1800px) {
        width: 75%;
    }
}

.imageOpration {
    display: inline-block;
    width: 45%;
    vertical-align: bottom;
    padding-left: 15px;

    @media screen and (max-width: 1800px) {
        width: 100%;
        padding-top: 10px;
        padding-left: 0;
    }
}

.MuiFormControl-root.MuiFormControl-marginDense {
    margin-top: 15px;
}

.frmEdit {
    @media only screen and (max-width: 767px) {
        padding: 0px !important;
    }
}

.MuiCardContent-root {
    @media only screen and (max-width: 767px) {
        padding: 15px !important;
    }
}

.MuiCardContent-root .MuiDivider-root {
    @media only screen and (max-width: 767px) {
        margin-top: 10px;
        margin-bottom: 0;
    }
}

.profileTableWrapper {
    .cardProfileDataContent {
        @media only screen and (max-width: 767px) {
            padding: 0 !important;
        }
    }
}

.imageOpration {
    .fileImageOperation {
        color: #ffffff !important;
        background-color: #2979ff !important;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        color: #263238;
        padding: 6px 16px;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        padding: 6px 22px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        border: 0;

        @media only screen and (max-width: 767px) {
            padding: 8px 14px !important;
            line-height: normal !important;
            font-size: 10px !important;
        }

        &:hover {
            background-color: #0d47a1 !important;
            cursor: pointer;
        }
    }
}

.btnAction {
    display: block !important;

    // padding-top: 15px !important;
    .MuiButton-contained {
        padding: 6px 22px !important;
        font-size: 0.875rem;

        @media only screen and (max-width: 767px) {
            padding: 8px 14px !important;
            line-height: normal !important;
            font-size: 10px !important;
            margin-bottom: 5px;
            margin-right: 5px !important;
            margin-left: 0 !important;
        }
    }

    &.databtnAction {
        padding-top: 0px !important;
    }
}

.reportBtnAction {
    display: block !important;
    padding: 0px !important;

    .MuiButton-contained {
        padding: 6px 22px !important;
        font-size: 0.875rem;

        @media only screen and (max-width: 767px) {
            line-height: normal !important;
            font-size: 10px !important;
            margin-bottom: 5px;
            margin-right: 5px !important;
            margin-left: 0 !important;
        }
    }

    &.databtnAction {
        padding-top: 0px !important;
    }
}

.MuiTooltip-popper {
    top: 3px !important;
}

.CompanyUserRoot {
    justify-content: flex-start !important;
}

/*login-page-css*/
.loginRoot {
    flex-direction: column;
    background-color: #f4f6f8;

    @media only screen and (max-width: 767px) {
        height: auto !important;
    }

    .MuiTypography-h3 {
        @media only screen and (max-width: 767px) {
            font-size: 16px;
            margin-top: 10px;
        }
    }

    .loginForm {
        @media only screen and (max-width: 767px) {
            margin-top: 10px;
        }
    }

    .forgotPasswordLinkWrapper {
        @media only screen and (max-width: 767px) {
            font-size: 12px;
        }
    }

    .loginCard {
        .loginContent {
            .loginIcon {
                border-radius: 4px;
                background-image: -moz-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
                background-image: -webkit-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
                background-image: -ms-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
                background-image: linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;

                @media only screen and (max-width: 767px) {
                    width: 42px;
                    height: 42px;
                    left: 16px;
                }
            }

            .MuiFormHelperText-contained {
                margin: -5px 0px 8px !important;
                font-size: 12px !important;
            }

            .MuiInputBase-fullWidth {
                margin-bottom: 20px;

                .MuiOutlinedInput-input {
                    @media only screen and (max-width: 767px) {
                        padding: 12px 14px;
                        height: 24px;
                    }
                }

                .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
                    @media only screen and (max-width: 767px) {
                        top: 0px;
                    }
                }
            }

            .Mui-error {
                margin-bottom: 10px !important;
            }

            .MuiIconButton-colorSecondary {
                padding: 0;
                margin-right: 10px !important;
                display: inline-block;
            }

            .MuiCheckbox-colorSecondary.Mui-checked {
                color: #2a5ab1;
            }

            .rememberMe {
                display: inline-block;
                width: 50%;
            }

            .submitButtonDiv {
                width: 50%;
                display: inline-block;
                margin-top: 0 !important;
            }

            .Mui-disabled.MuiButton-containedSecondary {
                background-color: rgba(0, 0, 0, 0.12);
                background-image: none !important;
            }

            .MuiButton-containedSecondary {
                background-image: -moz-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
                background-image: -webkit-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
                background-image: -ms-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
                background-image: linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
                color: #fff;

                @media only screen and (max-width: 767px) {
                    padding: 6px 22px;
                    font-size: 12px;
                }
            }
        }
    }

    .LogoSection {
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;
        justify-content: center;
    }

    .loginCard {
        flex-direction: row;
        overflow: visible !important;
    }
}

.formDivider {
    margin-bottom: 0px !important;
    margin-top: 0 !important;
    // @media only screen and (max-width : 767px){
    //     margin-top: 15px !important;
    // }
}

.dashLogo {
    padding-left: 70px;

    @media only screen and (max-width: 767px) {
        padding-left: 0 !important;
    }
}

.companyAction {
    @media only screen and (max-width: 767px) {
        padding-left: 15px !important;
    }
}

.profileImageOperation {
    background-color: rgba(38, 50, 56, 0.08) !important;
}

// header-css
.topbarRoot {
    .MuiToolbar-regular {
        min-height: auto;
        max-height: auto;
        padding: 0;
        background-color: #374aa8;
        display: flex;
        flex-direction: row;
        align-items: inherit;
        justify-content: space-between;

        @media (min-width: 1200px) and (max-width: 1399px) {
            position: relative;
        }

        .dashLogo {
            padding: 0;
            width: 18%;
            display: flex;
            flex-direction: column;

            @media only screen and (min-width: 1400px) and (max-width: 1800px) {
                width: 18%;
            }

            @media only screen and (min-width: 768px) and (max-width: 991px) {
                width: 100%;
                max-width: 189px;
            }

            @media only screen and (max-width: 767px) {
                width: 100%;
                max-width: 143px;
            }

            // @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation:landscape){
            //     width: 30%;
            // }

            a {
                width: 100%;

                img {
                    height: auto;
                    max-width: 100%;
                    object-fit: cover;
                    display: block;
                }
            }
        }

        .MuiIconButton-colorInherit {
            position: absolute;
            z-index: 9999;
            right: 0;
            top: 4px;

            @media only screen and (min-width: 768px) and (max-width: 991px) {
                top: 6px;
            }

            @media only screen and (max-width: 767px) {
                top: 0;
            }
        }

        .navigation {
            display: flex;
            flex-direction: column;
            // min-width: 52%;
            justify-content: flex-end;

            @media only screen and (min-width: 1400px) and (max-width: 1600px) {
                // width: 60%;
            }

            @media only screen and (min-width: 1600px) and (max-width: 1800px) {
                // width: 54%;
            }

            @media (min-width: 1200px) and (max-width: 1399px) {
                // width: 60%;
                padding-left: 20px;
            }

            .navigationRoot {
                margin-bottom: 0;

                .MuiList-padding {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;

                    .navItem {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        width: auto !important;
                        margin-right: 3px;

                        &:last-child {
                            margin-right: 0;
                        }

                        .navButtonLeaf,
                        .navButton {
                            color: #fff;
                            font-size: 16px;
                            text-transform: capitalize;
                            font-weight: 500;
                            padding: 14px 20px !important;
                            justify-content: center;
                            transition: all 0.3s;
                            background-color: transparent;
                            line-height: normal;
                            border: 0 !important;
                            border-top-left-radius: 10px !important;
                            border-top-right-radius: 10px !important;

                            @media only screen and (min-width: 1400px) and (max-width: 1800px) {
                                // font-size: 12px;
                                padding: 14px 18px !important;
                            }

                            @media (min-width: 1200px) and (max-width: 1399px) {
                                font-size: 16px;
                                padding: 14px 14px !important;
                            }

                            &.MuiButton-root {
                                border-radius: 0;
                            }

                            // .MuiTouchRipple-root{
                            //     background-color: #fff;
                            //     border-top-left-radius: 8px;
                            //     border-top-right-radius: 8px;
                            //     transition: all 0.3s;
                            //     overflow: inherit;
                            //     opacity: 0;
                            //     &:before {
                            //         content: '';
                            //         position: absolute;
                            //         top: -2px;
                            //         right: -18px;
                            //         width: 0;
                            //         height: 0;
                            //         border-left: 0px solid transparent;
                            //         border-right: 21px solid transparent;
                            //         border-bottom: 54px solid rgb(255,255,255);
                            //         z-index: 1;
                            //         border-top-left-radius: 20px;
                            //         opacity: 0;
                            //         -webkit-transition-delay: 0.1s;
                            //         transition-delay: 0.1s;

                            //     }
                            //     &:after {
                            //         content: '';
                            //         position: absolute;
                            //         top: -2px;
                            //         left: -18px;
                            //         width: 0;
                            //         height: 0;
                            //         border-left: 21px solid transparent;
                            //         border-right: 0px solid transparent;
                            //         border-bottom: 54px solid rgb(255,255,255);
                            //         z-index: 1;
                            //         border-top-right-radius: 20px;
                            //         opacity: 0;
                            //     }

                            //     }

                            &:hover,
                            &.active {
                                color: #221d5f;
                                background-color: #fff;
                            }

                        }

                        .navButtonLeaf .MuiSvgIcon-root {
                            display: none;
                        }
                    }

                    .subNavButtom {
                        position: relative;

                        &:hover {
                            .navButton {
                                color: #221d5f;
                                background-color: #fff;

                                .MuiSvgIcon-root {
                                    path {
                                        fill: #221d5f;
                                    }
                                }
                            }
                        }

                        .navButton {

                            &:hover,
                            &.active {
                                .MuiSvgIcon-root {
                                    path {
                                        fill: #221d5f;
                                    }
                                }
                            }

                            .MuiSvgIcon-root {
                                font-size: 18px;
                                margin-right: -4px;

                                &:not(.navExpandIcon) {
                                    display: none;
                                }

                                path {
                                    fill: #fff;
                                }
                            }
                        }

                        .MuiCollapse-root,
                        .MuiCollapse-container {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 190px;
                            background-color: #3f51b5;

                            .MuiList-root {
                                flex-wrap: wrap;
                                padding: 0 0;

                                li {
                                    width: 100% !important;
                                    margin: 0;

                                    a {
                                        justify-content: flex-start;
                                        font-size: 14px;
                                        padding: 10px 15px !important;
                                        border-radius: 0 !important;
                                        // &:hover {
                                        //     color: #fff;
                                        //     background-color: #24bdef;
                                        // }
                                    }

                                    &.active,
                                    &:hover {
                                        a {
                                            color: #fff;
                                            background-color: #24bdef;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .profileWrapper {
        display: flex;
        justify-content: flex-end;
        // min-width: 25%;
        align-items: center;
        padding-right: 125px;

        @media only screen and (min-width: 1400px) and (max-width: 1800px) {
            // min-width: 25%;
        }

        .profileWrapperDetails {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;

            &:hover {
                .profileSubmenu {
                    margin-top: 0;
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                }
            }

            .MuiAvatar-root {
                border: 2px solid #1477c2;
                width: 35px;
                height: 35px;

                @media (min-width: 1200px) and (max-width: 1399px) {
                    width: 35px;
                    height: 35px;
                }
            }

            .profileSubmenu {
                position: absolute;
                top: 100%;
                right: 0;
                min-width: 150px;
                padding: 0;
                margin-top: 10px;
                font-size: 14px;
                background-color: #ffffff;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                transition: 0.4s ease;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;

                &::before {
                    position: absolute;
                    top: -7px;
                    right: 10px;
                    display: inline-block;
                    border-right: 7px solid transparent;
                    border-bottom: 7px solid #fff;
                    border-left: 7px solid transparent;
                    content: "";
                }

                li {
                    list-style: none;
                    color: #364aa8;
                    font-size: 14px;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: 500;
                    transition: 0.4s ease;

                    &:hover {
                        color: #fff;
                        background-color: #24bdef;

                        a {
                            color: inherit;
                            text-decoration: none;
                        }
                    }

                    a {
                        display: block;
                        text-decoration: none;
                        padding: 10px 15px;
                    }
                }
            }

            .nameTypography {
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                margin-left: 15px;
                text-transform: capitalize;
                cursor: pointer;

                @media only screen and (min-width: 1400px) and (max-width: 1800px) {
                    font-size: 16px;
                }

                @media (min-width: 1200px) and (max-width: 1399px) {
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
        }

        .profileWrapperLogoutDetails {
            display: flex;
            align-items: center;
            margin-left: 40px;

            .makeStyles-logoutButton-16 {
                margin: 0;
                padding: 0;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

// page-css
.mainContent {
    background-color: #f4f6f8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .tableRoot {
        padding: 35px 40px;

        @media only screen and (max-width: 767px) {
            padding: 20px 15px;
        }
    }

    .MuiTableHead-root {
        background-color: #d4f2fc;

        .tabelLabel {
            color: #243f9e;
            font-size: 14px;
            font-weight: bold;
            line-height: normal;

            @media only screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
    }

    .MuiTableCell-root {
        @media only screen and (max-width: 767px) {
            padding: 10px;
            font-size: 12px;
            line-height: 14px;

            .TableCheckbox {
                padding: 0 !important;
            }
        }
    }

    .summary-table {
        // width: 80%;
        margin-right: auto;
        margin-left: auto;
        background-color: none !important;
        border: 1px solid #bbbbbb;

        .MuiTableHead-root {
            background-color: transparent;
            text-align: center;

            .MuiTableRow-root {
                .MuiTableCell-root {
                    text-align: center !important;
                }
            }
        }

        .MuiTableBody-root {

            .MuiTableRow-root {
                background-color: transparent !important;

                // &:nth-last-child(8) {
                //     border-top: 1px solid #bbbbbb;
                // }

                .MuiTableCell-root {
                    border: none;

                    &:nth-last-child(1),
                    &:nth-last-child(2) {
                        border-left: 1px solid #bbbbbb;
                    }
                }

                .numericValue {
                    text-align: center !important;
                }

            }

        }
    }

    .TableBody {
        cursor: default;

        tr {
            &.tableEven {
                background-color: #f2fbfe;
            }

            &.tableOdd {
                background-color: transparent;
            }
        }

        .TableCell {
            // word-break: break-word;

            @media only screen and (max-device-width: 768px) {
                min-width: 120px;
            }

            .MuiSvgIcon-fontSizeSmall {
                color: #1c96d6;
            }

            .actionLink {
                @media (max-width: 720px) {
                    margin: 0 0 0 1px !important;
                    // display: block !important;
                }

                margin-right: 7px !important;
            }

            .actionButton {
                color: #1c96d6;
                margin: 0;

                @media only screen and (min-width: 768px) and (max-width: 1199px) {
                    margin: 0;
                }

                @media only screen and (max-width: 767px) {
                    margin: 0;
                }

                .MuiSvgIcon-fontSizeSmall {
                    color: #1c96d6;

                    @media only screen and (min-width: 768px) and (max-width: 1199px) {
                        margin: 0;
                    }

                    @media only screen and (max-width: 767px) {
                        margin: 0;
                    }
                }
            }
        }
    }

    .PlantProfileTableBody {
        .tr {
            &.tableEven {
                background-color: #f2fbfe;
            }

            &.tableOdd {
                background-color: transparent;
            }
        }

        .TableCell {
            .MuiSvgIcon-fontSizeSmall {
                color: #1c96d6;
            }

            .actionLink {
                @media only screen and (max-width: 767px) {
                    margin: 0;
                    margin-left: 3px;
                }
            }

            .actionButton {
                color: #1c96d6;

                @media only screen and (min-width: 768px) and (max-width: 1199px) {
                    margin: 0;
                }

                @media only screen and (max-width: 767px) {
                    margin: 0;
                }

                .MuiSvgIcon-fontSizeSmall {
                    color: #1c96d6;

                    @media only screen and (min-width: 768px) and (max-width: 1199px) {
                        margin: 0;
                    }

                    @media only screen and (max-width: 767px) {
                        margin: 0;
                    }
                }
            }

            .actionLink {
                margin-right: 0;
            }
        }
    }

    .MuiGrid-item {
        .tableHeaderAddButton {
            background-image: -moz-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
            background-image: -webkit-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
            background-image: -ms-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
            background-image: linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
        }
    }

    .activebtn {
        background-image: -moz-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
        background-image: -webkit-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
        background-image: -ms-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
        background-image: linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
        color: #fff !important;
        margin-right: 0 !important;

        width: auto;

        @media only screen and (max-width: 767px) {
            font-size: 10px;
        }
    }

    // .MuiButton-label{
    //     font-size: 14px;
    // }
    .btnAction .MuiButton-contained {
        font-size: 14px;

        @media only screen and (max-width: 767px) {
            font-size: 10px;
        }
    }

    .inActivebtn {
        color: #3f51b5 !important;
        border: 1px solid rgba(63, 81, 181, 0.5);
        padding: 5px 9px;
        font-size: 14px;
        background-color: transparent !important;
        box-shadow: none !important;
        margin-right: 0 !important;
        line-height: 22px;
        width: auto;

        @media only screen and (max-width: 767px) {
            font-size: 10px;
        }
    }

    .frmEdit .Mui-disabled {
        color: rgba(0, 0, 0, 0.26) !important;
        box-shadow: none !important;
        // background-color: rgba(0, 0, 0, 0.12) !important;
        background-image: none !important;
        // background-color: transparent !important;
        cursor: not-allowed;

        .MuiSelect-icon {
            color: rgba(0, 0, 0, 0.26) !important;
        }
    }

    // .cardHeader{
    //     background-color: #d4f2fc;
    // }
    .formRoot {
        padding: 0;

        .MuiCardContent-root {
            .MuiTypography-colorTextSecondary {
                text-align: center;

                @media only screen and (max-width: 767px) {
                    text-align: center;
                }
            }
        }

        .MuiCardActions-root {
            justify-content: center;
            // padding-top: 0;
            // padding-left: 0 !important;
        }

        .userAvtar {
            margin-bottom: 10px;
        }

        .MuiTypography-gutterBottom {
            margin-bottom: 5px !important;
            margin-left: 15px !important;
        }
    }

    .reportPageTitle {
        padding-top: 10px;
        margin-bottom: 24px;
    }

    // .pageTitle {
    //     margin-bottom: 30px;

    //     @media only screen and (max-width: 767px) {
    //         margin-bottom: 18px;
    //     }
    // }

    .GroupPageTitle {
        margin-bottom: 15px;

        @media only screen and (max-width: 767px) {
            margin-bottom: 9px;
        }
    }

    .GroupPageTitle {
        margin-bottom: 15px;

        @media only screen and (max-width: 767px) {
            margin-bottom: 9px;
        }
    }

    .formCardContent {
        padding-bottom: 8px;
        justify-content: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media only screen and (max-width: 767px) {
            padding-bottom: 0 !important;
        }

        .ReactCrop {
            width: 200px;
        }

        .MuiTypography-gutterBottom {
            @media only screen and (max-width: 767px) {
                font-size: 14px;
                margin-bottom: 0;
            }
        }

        .imageoprationDiv {
            .activebtn {
                @media only screen and (max-width: 767px) {
                    font-size: 10px;
                }
            }
        }

        .MuiTypography-colorTextSecondary {
            margin-top: 10px;
        }
    }

    .imageoprationDiv {
        justify-content: center !important;
    }

    .cardInner {
        width: 100% !important;
        // overflow-x: auto;
        overflow-y: hidden;
    }
}

.MuiDrawer-modal {
    .activebtn {
        background-image: -moz-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
        background-image: -webkit-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
        background-image: -ms-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
        background-image: linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;

        @media only screen and (max-width: 767px) {
            font-size: 10px;
        }
    }

    .inActivebtn {
        color: #3f51b5;
        border: 1px solid rgba(63, 81, 181, 0.5);
        padding: 3px 9px;
        font-size: 14px;
        margin-left: 0;

        @media only screen and (max-width: 767px) {
            font-size: 10px;
        }
    }
}

// sidebar-section-css
.MuiDrawer-paperAnchorLeft {
    min-width: 256px;
    box-shadow: 0px 3px 26px #00000029;
    background-color: #fdfdfd;
    height: 100%;
    display: flex;
    overflow-y: auto;

    .topBar {
        height: 100%;
        display: flex;
        flex-direction: column;

        // border-bottom: 1px solid #ddd;
        .profileWrapperDetails {
            padding: 15px 0px;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #ddd;

            .MuiAvatar-root {
                margin-bottom: 10px;
            }

            .MuiTypography-h4 {
                @media only screen and (min-width: 768px) and (max-width: 1199px) {
                    font-size: 18px;
                }

                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }

        .navigation {
            flex: 1 1 auto;

            .navigationRoot {
                margin: 0;

                .MuiList-padding {
                    margin: 0;
                    padding: 0;

                    .navItem {

                        .navButtonLeaf,
                        .navButton {
                            padding: 16px 22px !important;
                            line-height: normal;
                            background-color: transparent;
                            border-radius: 0;

                            @media only screen and (max-width: 767px) {
                                font-size: 12px;
                                padding: 8px 22px !important;
                            }

                            &.active {
                                background-color: #3f51b5;
                                color: #fff;

                                @media only screen and (max-width: 1279px) {
                                    background-color: #fff;
                                    color: #3f51b5;
                                }
                            }
                        }
                    }
                }
            }
        }

        .subNavButtom {
            .MuiList-root {
                .navItem {
                    padding-left: 40px;
                }
            }
        }
    }

    .profileWrapperLogoutDetails {
        align-items: flex-end;
        padding: 0px 30px 22px;

        @media only screen and (max-width: 1279px) {
            padding: 0px 22px 22px;
        }

        .MuiButton-colorInherit {
            margin-left: 0;
            width: 100%;
            justify-content: center;
            text-transform: capitalize;
            font-size: 14px;
            color: #fff;
            align-items: center;

            @media only screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
    }

    .adminNavbar {
        height: 100%;
        display: flex;
        flex-direction: column;

        .navigation {
            flex: 1 1 auto;
            margin-top: 0;

            .navItem {
                position: relative;

                .navButtonLeaf,
                .navButton {
                    padding: 10px 22px !important;
                }

                &.active {
                    color: #243f9e !important;

                    .navButtonLeaf,
                    .navButton {
                        color: #243f9e !important;
                    }
                }
            }
        }

        .profileWrapperLogoutDetails {
            .MuiButton-label {
                .logoutIcon {
                    margin-right: 6px;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    .profileWrapperDetails {
        padding: 15px 0px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #ddd;

        .MuiAvatar-root {
            margin-bottom: 10px;
        }

        .MuiTypography-h4 {
            @media only screen and (min-width: 768px) and (max-width: 1199px) {
                font-size: 18px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 16px;
            }
        }
    }
}

.singleRaw {
    margin-top: 20px !important;
}

.singleOneRaw {
    margin-top: 17px !important;
}

// table-structure-css
.mainContent {
    overflow: scroll !important;

    // overflow-y: hidden !;
    .tableRoot {
        padding: 35px 30px;

        @media only screen and (max-width: 767px) {
            padding: 20px 15px;
        }

        .frmEdit {
            padding: 0;

            .frmGrid {
                margin: 0;
                padding: 0;

                .MuiGrid-item {
                    padding: 0;
                }
            }

            .MuiPaper-rounded {
                margin: 0;
            }

            .MuiOutlinedInput-root {
                @media only screen and (max-width: 767px) {
                    font-size: 12px;
                }

                .MuiOutlinedInput-inputMultiline {
                    padding-right: 15px;
                    font-size: 14px;
                }
            }

            .MuiCardActions-root {
                @media only screen and (max-width: 767px) {
                    padding-top: 14px;
                    justify-content: left;
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }
            }

            .frmCardContent {
                .MuiGrid-container {
                    margin: -8px;
                }

                // .MuiGrid-container > .MuiGrid-item{
                //     padding: 16px;
                //     @media only screen and (max-width : 767px){
                //         padding: 8px;
                //     }
                // }
                .datadefinitionLabel {
                    padding-left: 15px;

                    @media only screen and (max-width: 992px) {
                        margin-top: 10px;
                        padding-left: 0;
                        padding: 0 !important;
                    }
                }

                .MuiInputBase-root {
                    font-size: 14px;
                }

                .MuiOutlinedInput-inputMarginDense {
                    padding-top: 15.5px !important;
                    padding-bottom: 15.5px !important;
                }

                .uiOutlinedInput-notchedOutline {
                    top: -2px;
                }

                // @media only screen and (max-width : 767px){
                //     padding-bottom: 5px !important;
                // }
                .MuiTypography-gutterBottom {
                    @media only screen and (max-width: 767px) {
                        margin-bottom: 0;
                        font-size: 14px;
                        margin-top: 10px;
                    }
                }

                .MuiOutlinedInput-inputMarginDense {
                    padding-top: 15.5px !important;
                    padding-bottom: 15.5px !important;
                }

                .MuiOutlinedInput-inputMarginDense {
                    @media only screen and (max-width: 767px) {
                        padding-top: 15.5px !important;
                        padding-bottom: 15.5px !important;
                    }
                }

                .MuiOutlinedInput-notchedOutline {
                    @media only screen and (max-width: 767px) {
                        top: 0 !important;
                    }
                }

                .frmCardGrid.frmEditGrid {
                    padding-top: 10px;
                }

                .tagGrid {
                    .chipTag {
                        @media only screen and (max-width: 767px) {
                            margin: 5px;
                            min-width: 200px;
                            max-width: 250px;
                            line-height: 16px;
                            height: auto;
                            justify-content: start;
                        }

                        .MuiChip-label {
                            @media only screen and (max-width: 767px) {
                                overflow: hidden;
                                white-space: normal;
                                padding-left: 12px;
                                padding-right: 12px;
                                text-overflow: ellipsis;
                                padding: 7px 12px;
                                font-size: 12px;
                            }
                        }
                    }
                }

                .CommonAccountDescription {
                    .MuiOutlinedInput-multiline {
                        padding: 0;
                        // .MuiOutlinedInput-input{
                        //   min-height: 85px;
                        //   @media only screen and (max-width : 767px){
                        //       min-height: 50px;
                        //   }
                        // }
                    }
                }
            }

            .profileactionBtn {
                padding-top: 16px;
                justify-content: flex-start;
            }

            .datadefinitionCard {
                margin-bottom: 24px;
            }

            .formCheckbox {
                padding: 0;
                padding-left: 8px;
                padding-right: 8px;
            }

            .datadefinitionLabel {
                padding-left: 15px;

                @media only screen and (max-width: 992px) {
                    margin-top: 10px;
                    padding-left: 0;
                    padding: 0 !important;
                }
            }

            .frmCardContent {
                .plantSelectDiv {
                    justify-content: center;
                    align-items: center;
                }

                .menuReportField {
                    width: 45%;

                    @media only screen and (max-width: 767px) {
                        width: 69%;
                    }
                }

                .addButtonTag {
                    margin-top: 9px;

                    .MuiSvgIcon-root {
                        width: 20px !important;
                    }
                }

                &.frmProfileEditGrid {
                    padding: 15px !important;
                }
            }

            .plantdesc {
                @media only screen and (max-width: 767px) {
                    label {
                        margin-bottom: 5px;
                        display: inline-block;
                        font-size: 14px;
                    }
                }
            }

            .textAreaGrid {
                .MuiOutlinedInput-root {
                    @media only screen and (max-width: 992px) {
                        // padding: 0 !important;
                        margin-top: 0px;
                        margin-bottom: 10px;
                        padding: 0;
                    }
                }
            }

            .frmCardContent {
                padding: 15px !important;
            }

            .permissionTableRow {
                .tableHeading {
                    padding: 0px 6px !important;
                }

                .tableHead {
                    width: 10%;
                }
            }
        }

        .tableHeaderPageHeader {
            @media only screen and (max-width: 767px) {
                margin-bottom: 15px !important;
            }
        }

        .pageTitle {
            @media only screen and (min-width: 768px) and (max-width: 1199px) {
                font-size: 18px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 16px;
                line-height: normal;
            }
        }

        .MuiGrid-item .tableHeaderAddButton {
            @media only screen and (min-width: 768px) and (max-width: 1199px) {
                font-size: 12px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 10px;
            }
        }

        .MuiButton-outlinedSizeSmall {
            @media only screen and (min-width: 768px) and (max-width: 1199px) {
                font-size: 12px;
                padding: 5px 9px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 10px;
                padding: 2px 9px;
            }
        }

        .MuiTableCell-head {

            .MuiCheckbox-root,
            .MuiIconButton-colorPrimary {
                @media only screen and (min-width: 768px) and (max-width: 1199px) {
                    padding: 0;
                }
            }
        }

        .MuiTableCell-body {

            .MuiCheckbox-root,
            .MuiIconButton-colorPrimary {
                @media only screen and (min-width: 768px) and (max-width: 1199px) {
                    padding: 0;
                }
            }
        }
    }

    .displayHide {

        // padding: 0 !important;
        @media only screen and (max-width: 767px) {
            display: none !important;
        }
    }

    .btnAction> :not(:first-child) {
        margin-left: 8px !important;
    }

    .MuiTabs-root {
        @media only screen and (max-width: 767px) {
            margin-top: 0;
        }
    }
}

.TablePagination {
    overflow: hidden !important;

    @media only screen and (max-width: 767px) {
        .MuiTablePagination-toolbar {
            padding-right: 0;
            padding-left: 0;
            min-height: 28px;
        }

        .MuiTablePagination-spacer {
            flex: inherit;
        }

        .MuiIconButton-root {
            padding: 0;
        }

        .tableFilterContentSectionHeader {
            margin-bottom: 10px;
        }

        .MuiTablePagination-selectRoot {
            margin-right: 6px;
            margin-left: 0;
        }

        .MuiTablePagination-actions {
            margin-left: 5px;
        }

        .MuiToolbar-root {
            justify-content: center;
        }
    }
}

// admin-css
.adminPageRoot {
    .topbarRoot {
        .MuiToolbar-gutters {
            justify-content: space-between;
        }

        // .dashLogo{
        //     min-width: auto;
        //     width: auto;
        // }
        // .profileWrapper{
        //  min-width: auto;
        //  width: auto;
        // }
    }

    .MuiTableCell-head {
        padding: 16px 6px;
    }

    .TableCell {
        max-width: 250px !important;
        padding: 16px 6px;

        .actionButton {
            margin: 0;
        }

        &.restrcitedTableCell {
            // padding: 16px;
        }
    }

    .adminNavbar {
        background-color: #f2fbfe;
        height: auto;
    }

    .navContent {
        padding: 0;

        .navigation {
            flex: 1 1 auto;

            .navItem {

                .navButtonLeaf,
                .navButton {
                    padding: 10px 22px !important;
                }

                &.active {
                    color: #243f9e !important;

                    .navButtonLeaf,
                    .navButton {
                        color: #243f9e !important;
                    }
                }

                &.subNavButtom {
                    .navButtonLeaf {
                        padding-left: 40px !important;
                    }
                }
            }
        }
    }
}

//loader-css
.page-loader {
    color: #374aa8;
    font-size: 8px;
}

.group-page-loader {
    color: #374aa8;
    font-size: 8px;
}

// filter-css

.tableFilterContentSectionHeader {
    margin-bottom: 10px;
}

.tableFilterContentSectionContent {
    margin-top: 10px !important;
}

.tableFilterActions {
    .MuiButton-root {
        padding: 8px 16px;
    }

    .activebtn {
        padding: 10px 16px;
    }
}

.tableFilterContentSectionContent {

    // @media only screen and (max-width : 767px){
    //     padding-bottom: 5px !important;
    // }
    .MuiTypography-gutterBottom {
        @media only screen and (max-width: 767px) {
            margin-bottom: 0;
            font-size: 14px;
            margin-top: 10px;
        }
    }

    .MuiOutlinedInput-input {
        @media only screen and (max-width: 767px) {
            padding: 14px 14px;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        @media only screen and (max-width: 767px) {
            top: 0 !important;
        }
    }

    .tableFilterFormGroup {
        padding: 10px 0px;
    }

    .MuiTypography-gutterBottom {
        @media only screen and (max-width: 767px) {
            margin-bottom: 0;
            font-size: 14px;
            margin-top: 10px;
        }
    }

    .MuiOutlinedInput-input {
        @media only screen and (max-width: 767px) {
            padding: 14px 14px;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        @media only screen and (max-width: 767px) {
            top: 0 !important;
        }
    }

    .MuiOutlinedInput-input {
        padding: 16px 14px;
    }

    .uiOutlinedInput-notchedOutline {
        top: -2px;
    }
}

.loginRoot {
    .MuiGrid-container>.MuiGrid-item {
        padding: 16px;

        @media only screen and (max-width: 767px) {
            padding: 8px;
        }
    }

    .datadefinitionLabel {
        padding-left: 15px;

        @media only screen and (max-width: 992px) {
            margin-top: 10px;
            padding-left: 0;
            padding: 0 !important;
        }
    }

    .MuiOutlinedInput-input {
        padding: 16px 14px;
    }

    .uiOutlinedInput-notchedOutline {
        top: -2px;
    }

    // @media only screen and (max-width : 767px){
    //     padding-bottom: 5px !important;
    // }
    .MuiTypography-gutterBottom {
        @media only screen and (max-width: 767px) {
            margin-bottom: 0;
            font-size: 14px;
            margin-top: 10px;
        }
    }

    .MuiOutlinedInput-input {
        @media only screen and (max-width: 767px) {
            padding: 14px 14px;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        @media only screen and (max-width: 767px) {
            top: 0 !important;
        }
    }
}

.textAreaTextField textarea {
    padding: 0 !important;
}

.selectedCompanies {
    padding-left: 16px;
}

.chipTag {
    margin: 5px;
}

.tagGrid {
    padding-top: 10px !important;
}

.MuiChip-deleteIcon {
    height: 16px !important;
}

.permissionTableCell {
    border-bottom: 0 !important;
}

.permissionTableHead {
    background-color: transparent !important;
}

.permissionTableCell {
    text-align: center !important;
}

.permissionTableCell {
    text-align: center !important;
}

.tableHeading {
    text-align: left !important;
    padding: 16px !important;
}

.DataNameLi {
    padding-left: 16px;
    font-family: "Roboto, Helvetica, Arial, sans-serif";
    font-size: 14px;

    //   padding-top: 15px;
    @media only screen and (max-width: 767px) {
        padding-top: 0 !important;
    }
}

.errorBlock {
    color: #e53935;
    font-size: 12px;
    margin: 5px 0px 8px;
    min-height: 1em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0.33px;
}

.textAreaGridLabel {
    width: 100% !important;
    display: inline-block;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.textAreaGrid {
    width: 100%;
    display: inline-block;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.errorLabel {
    color: #e53935 !important;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

// Sedhal added
.MuiFormHelperText-contained {
    margin: 5px 0px 8px !important;
    font-size: 12px !important;
}

.dataDefinationFieldHide {
    display: none;
}

.cardProfileDataContent:last-child {
    // padding-bottom: 0 !important;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .dataDefinationFieldHide {
        display: block !important;
        padding: 0 8px !important;
    }

    .frmEdit {
        .MuiGrid-spacing-xs-2 {
            margin-bottom: 10px;
        }

        .MuiGrid-spacing-xs-2>.MuiGrid-item {
            padding: 7px 8px !important;
        }

        .MuiTypography-h5 {
            font-size: 14px;
        }
    }

    .desktop_view {
        display: none !important;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .dataDefinationFieldHide {
        display: block !important;
        padding: 0 8px !important;
    }

    .frmEdit {
        .MuiGrid-spacing-xs-2 {
            margin-bottom: 10px;
        }

        .MuiGrid-spacing-xs-2>.MuiGrid-item {
            padding: 4px 8px !important;
        }

        .MuiTypography-h5 {
            font-size: 14px;
        }
    }

    .desktop_view {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .dataDefinationFieldHide {
        display: block !important;
        padding: 0 8px !important;
    }

    .frmEdit {
        .MuiGrid-spacing-xs-2 {
            margin-bottom: 10px;
        }

        .MuiGrid-spacing-xs-2>.MuiGrid-item {
            padding: 4px 8px !important;
        }
    }

    .desktop_view {
        display: none !important;
    }
}

// Design changes in table section
.tableRoot .prev_next_section .MuiTypography-root a svg.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    margin: 0px 0px;
    display: inline-block;
    vertical-align: middle;

    @media only screen and (max-width: 767px) {
        width: 16px;
        height: 16px;
    }
}

.frmCardContent .MuiInputBase-fullWidth .MuiSelect-selectMenu {
    @media only screen and (max-width: 767px) {
        padding: 16px 14px;
    }
}

.tableRoot .prev_next_section .MuiTypography-body1 {
    color: #243f9e !important;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;

    @media only screen and (max-width: 767px) {
        font-size: 12px;
    }

    .tableRoot .prev_next_section .MuiTypography-body1 a {
        color: #243f9e !important;
    }
}

// .adminPageRoot .cardProfileDataContent .MuiGrid-item{ width: 100%;
//     margin: 0 auto;
//     height: 100%;
// }
// .adminPageRoot .cardProfileDataContent .cardInner .table-inner-section{
//     height: auto !important;
// }
// .adminPageRoot .cardProfileDataContent table.MuiTable-root  {
//         border-collapse: collapse;
//         width: 100%;
//         -webkit-overflow-scrolling: touch;
//         -webkit-border-horizontal-spacing: 0;
//       }
//       .adminPageRoot .cardProfileDataContent table.MuiTable-root th{
//         word-wrap: break-word;
//         word-break: break-all;
//         -webkit-hyphens: auto;
//         hyphens: auto;
//         width: 10%;
//         min-width: 100px;
//         color: #243f9e;
//         font-size: 14px;
//         font-weight: bold;
//         line-height: normal;
//         padding: 32px;

//         @media only screen and (max-width: 767px){
//             font-size: 12px;
//             min-width: 80px;
//             padding: 16px;
//         }
//         @media only screen and (max-width: 1199px) and (min-width: 768px){
//             padding: 16px;
//         }
//       }
//   .adminPageRoot .cardProfileDataContent table.MuiTable-root  td {
//     // text-align: left;
//     // padding: 10px 10px;
//     width: 10%;
//     min-width: 100px;
//     word-wrap: break-word;
//     word-break: break-all;
//     -webkit-hyphens: auto;
//     hyphens: auto;
//     @media only screen and (max-width: 767px){
//         padding: 10px;
//         font-size: 12px;
//         line-height: 14px;
//         min-width: 80px;
//     }
//   }
//   .adminPageRoot  .cardProfileDataContent table.MuiTable-root  td:first-child,.adminPageRoot .cardProfileDataContent table.MuiTable-root  td:nth-child(2),
//   .adminPageRoot  .cardProfileDataContent table.MuiTable-root th:first-child,.adminPageRoot .cardProfileDataContent table.MuiTable-root  th:nth-child(2){
//     min-width: 200px;
//     @media only screen and (max-width: 767px){
//         min-width: 110px;
//     }

// }

// .adminPageRoot  .cardProfileDataContent table.MuiTable-root .TableHead tr {
//     display: block;
//     position: relative;
//   }
//   .adminPageRoot  .cardProfileDataContent table.MuiTable-root .TableBody  {
//     display: block;
//     overflow: auto;
//     height: 500px;
//     overflow-x: hidden;
//     @media only screen and (max-width: 1399px) and (min-width: 1200px){
//         max-height: 500px;
//     }
//     @media only screen and (max-width: 1199px) and (min-width: 992px){
//         max-height: 370px;
//     }
//     @media only screen and (max-width: 991px) and (min-width: 768px){
//         max-height: 700px;
//     }
//     @media only screen and (max-width: 767px){
//         max-height: 320px;
//     }
//   }

.easy-edit-inline-wrapper {
    .easy-edit-component-wrapper {
        margin-bottom: 0;

        input {
            // width: 100px;
            // max-width: 100px;
            border-radius: 4px;
            padding: 8px;
            font-size: 12px;
            border-color: rgba(0, 0, 0, 0.15);
            border-width: 1px;

            @media only screen and (max-width: 767px) {
                padding: 5px;
                font-size: 10px;
            }
        }
    }

    .easy-edit-button {
        margin: 0 2px;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        background-image: linear-gradient(0deg, #374aa8 0%, #1477c2 100%) !important;
        color: #fff;
        font-size: 11px;

        @media only screen and (max-width: 767px) {
            font-size: 10px;
            padding: 4px 10px;
        }
    }
}

//  admin-css
// .cardProfileContent:last-child {
//     padding-bottom: 0 !important;
// }
// .cardProfileDataContent table.MuiTable-root th, .cardProfileDataContent table.MuiTable-root td{
//     min-width: 180px;
//     @media only screen and (max-width: 767px){
//         min-width: 120px !important;
//     }
// }
// .adminPageRoot .cardProfileDataContent table.MuiTable-root th, .adminPageRoot .cardProfileDataContent table.MuiTable-root td{
//     min-width: 180px;
//     padding:16px 10px;
//     @media only screen and (max-width: 767px){
//         min-width: 120px !important;
//     }
// }
// table-css
.dataTables_wrapper .dataTables_info {
    display: none;
}

table.dataTable.display tbody th,
table.dataTable.display tbody td {
    border-top: 0 !important;
    word-wrap: break-word;
    word-break: break-all;
    -webkit-hyphens: auto;
    hyphens: auto;
    width: 10%;
    min-width: 100px;
    font-size: 14px;
    line-height: normal;
    padding: 8px;
    border-top: 0;
    border-bottom: 1px solid #eeeeee;
    padding: 16px;
}

table.dataTable thead th {
    padding: 16px;
    color: #243f9e;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    border-bottom: 1px solid #eeeeee;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td {
    border-top: 0;
    padding: 16px;
    word-wrap: break-word;
    word-break: break-all;
    -webkit-hyphens: auto;
    hyphens: auto;
    padding: 16px;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td {
    vertical-align: top;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td {
    vertical-align: top;
}

table.dataTable tbody th,
table.dataTable tbody td {
    border-top: 0 !important;
    word-wrap: break-word;
    word-break: break-all;
    -webkit-hyphens: auto;
    hyphens: auto;
    width: 10%;
    min-width: 100px;
    font-size: 14px;
    line-height: normal;
    border-top: 0;
    padding: 16px;
}

table.dataTable {
    margin: inherit !important;
}

table.dataTable thead th,
table.dataTable.row-border tbody th {
    padding: 32px;

    @media only screen and (max-width: 767px) {
        padding: 16px;
    }
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 0 !important;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody>table {
    background-color: transparent !important;
}

// table.dataTable tbody td {
//     padding: 16px 10px;
// }
.MuiTable-root.stripe.order-column.dataTable.no-footer.DTFC_Cloned {
    background-color: transparent !important;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr,
table.dataTable tbody tr {
    height: 70px;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td {
    //vertical-align: top;
    vertical-align: middle;
    padding: 0px !important;
    word-wrap: break-word;
    // padding-right: 20px !important;
    text-align: left;

    button {
        padding: 6px 10px;
        font-size: 10px;

        @media only screen and (max-width: 767px) {
            padding: 4px 10px;
            font-size: 7px;
        }
    }
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th,
table.dataTable thead th {
    padding-left: 0;

    .month-link {
        background-color: #243f9e;
        color: #fff;
        font-size: 12px;
        padding: 4px;
    }
}

.dataTables_scrollHeadInner {
    padding-right: 0 !important;
}

.btnAction.MuiCardActions-spacing {
    text-align: left;
}

.frmEdit .title-wrapper {
    .MuiCardActions-root {
        padding: 0 !important;
    }

    .MuiGrid-grid-xs-12 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;

        .MuiCardActions-root {
            padding: 0 !important;
            font-size: 15px;
        }

        .MuiFormLabel-root {
            @media only screen and (max-width: 767px) {
                font-size: 15px !important;
            }
        }
    }
}

.adminPageRoot {
    .frmCardContent {
        .MuiGrid-spacing-xs-4>.MuiGrid-item {
            padding: 8px;
        }
    }
}

.plantTableRoot {
    padding: 0 !important;
}

.plantTablefilter {
    margin-top: 10px !important;
}

.drawer-fix-width .MuiDrawer-paper {
    width: 750px;
}

.textarea_fix_padding div {
    padding: 19.5px 14px !important;
}

.groupSubdiv {
    margin-top: 30px !important;
    padding: 24px !important;
}

.clientDashboard .tableRoot {
    padding: 0 !important;
}

.sticky-table-table {
    width: 100%;
}

.ExpansionPanelSummary {
    flex-direction: row-reverse;
}

body .ExpansionPanelSummary {
    padding: 0;
    min-height: auto !important;
}

.ExpansionPanelSummary p {
    font-weight: 600;
    font-size: 16px;
    padding-left: 0px;
}

.ExpansionPanelDetailsUl {
    margin-left: 15px;
    width: 100%;
}

// ul.ExpansionPanelDetailsUl {
//     list-style-type: none;
//     padding-left: 0;
//     display: block;
//     width: 100%;
//     padding-left: 50px;
// }
// ul.ExpansionPanelDetailsUl li {
//     margin: 4px 0;
//     font-weight: 400;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//     font-size: 14px;

// }
body .ExpansionPanel:before {
    background: transparent;
}

body .ExpansionPanelSummary>div.Mui-expanded:before {
    content: "- ";
    font-size: 36px;
    margin-top: -5px;
}

body .ExpansionPanelSummary>div:before {
    content: "+ ";
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 26px;
    transform: translateY(-50%);
    font-weight: 600;
}

.ExpansionPanel {
    margin: 0 !important;
    border-bottom: none !important;
    box-shadow: none !important;
    padding: 8px;
}

body .ExpansionPanelDetails {
    padding: 0;
}

.sidebar {
    background: #fff;
    border: 1px solid #000;
}

.rightSidebarMenu {
    background: #fff;
    border: 1px solid #000;
}

body .ExpansionPanelSummary>div {
    padding: 0 15px;
    position: relative;
    margin: 12px 0 !important;
}

.accordianTitle {
    padding: 15px;
    padding-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-decoration: underline;
    margin-bottom: 10px;
}

// 8-6-20
.clientDashboard {
    position: relative;
    flex-wrap: wrap;

    .clientDashboardGrid table {
        table-layout: auto;

        * {
            box-sizing: content-box;
        }

        th,
        td {
            // min-width: auto !important;
            border: 1px solid #bbbbbb;
        }

        tbody tr.MuiTableRow-root:not(.tableOdd):not(.tableEven) {
            background-color: #f2fbfe;
        }

        .MuiTableCell-root {
            padding: 5px 6px;
        }

        .MuiTableCell-head {
            color: #374aa8;
        }

        // th:nth-child(1),
        // td:nth-child(1)
        th:nth-child(3),
        td:nth-child(3) {
            text-align: center;
        }

        th:nth-child(4),
        th:nth-child(5),
        th:nth-child(6),
        th:nth-child(7),
        th:nth-child(8),
        th:nth-child(9),
        th:nth-child(10) {
            // text-align: right;
        }

        td:nth-child(4),
        td:nth-child(5),
        td:nth-child(6),
        td:nth-child(7),
        td:nth-child(8),
        td:nth-child(9),
        td:nth-child(10) {
            // text-align: right;
        }
    }

    .accordianTitle {
        padding: 15px;
        background-color: #374aa8;
        text-decoration: none;
        color: #fff;
    }


}

.SameLoadedConent,
.toolChart {
    .document-sections table th:first-child {
        width: 18px !important;
    }

    .datatableAjax th:first-child {
        width: auto !important;
    }

    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(3),
    th:nth-child(6),
    th:nth-child(7),
    th:nth-child(8),
    th:nth-child(9),
    th:nth-child(10) {
        text-align: left !important;
    }

    td:nth-child(4),
    td:nth-child(3),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7),
    td:nth-child(8),
    td:nth-child(9),
    td:nth-child(10) {
        text-align: left !important;
    }
}

.chartjs-render-monitor {
    height: 600px !important;
}

/* .reportInner {
    padding-top: 15px;
} */
.reportHeading {
    padding-top: 10px !important;
}

.DateInput_input {
    font-size: 15px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    color: #000;
}

.dataname {
    cursor: pointer;
}

.chart-tool-right-div .inActivebtn {
    color: #3f51b5 !important;
    border: 1px solid rgba(63, 81, 181, 0.5);
    padding: 5px 9px;
    font-size: 14px;
    background-color: transparent !important;
    box-shadow: none !important;
    margin-right: 15px !important;
    line-height: 22px;
    width: auto;
}

.rangepicker {
    display: flex;
    justify-content: flex-end;
    align-items: center !important;

    .activebtn {
        margin-right: 5px !important;
    }

    @media only screen and (max-device-width: 1199px) {
        flex-wrap: wrap;
    }

    @media only screen and (max-width: 600px) {
        justify-content: flex-start;
    }
}

.rangepicker button {
    margin: 0 !important;
    margin-left: 5px !important;
    padding: 0px 10px;
    line-height: 48px;
    height: 35px;

    @media only screen and (max-width: 1200px) {
        margin-bottom: 5px !important;
    }

    @media only screen and (max-width: 600px) {
        height: auto;
        padding: 10px;
        line-height: normal;
        margin-left: 0 !important;
        //margin-right: 5px !important;
        margin-bottom: 5px !important;
    }
}

.stripe.row-border.report-page {
    width: 100%;
    min-width: 900px;

    .TableCell {
        word-break: normal !important;
    }
}

.reportCardContent {
    // height: calc(100% - 125px);
    overflow: visible !important;
}

.clientDashboard,
.clientDashboardGrid {
    width: 100%;
    display: flex;
}

.clientDashboard-report {
    justify-content: space-between;
    align-items: flex-start;
    // margin-top: 25px;
}

.clientDashboardGrid .sidebar {
    width: 255px;
}

.clientDashboardGrid .rightSidebarMenu {
    width: 255px;
}

.clientDashboardGrid .dashboardMainView {
    width: calc(100% - 270px);
    // width: 100%;
    // padding-left: 15px;
}

.clientDashboard-chart .dashboardMainView {
    width: 100%;
    // padding-left: 15px;
}

.DayPicker {
    left: -70px;
}

.GroupTable td {
    border: 1px solid #bbb !important;
}

.GroupTableOtherSetting td {
    border: 1px solid #bbb !important;
}

.GroupTableOtherSetting .TableBody td {
    padding: 0px 6px;
}

.word-wrap-field-text {
    word-break: break-all;
}

.actionIcon {
    margin: 0 !important;
}

.textarea-display .MuiInputBase-inputMultiline {
    overflow: hidden;
}

.categoryLink {
    cursor: pointer;
}

.TableLink {
    cursor: pointer;
}

.categoryLinkActive span {
    color: #374aa8;
    font-weight: 700;
}

.categoryLink span:hover {
    color: #374aa8;
}

.menuActive {
    cursor: pointer;
}

.menuActive span {
    color: #374aa8;
    font-weight: 700;
}

.menuActive span:hover {
    color: #374aa8;
}

.front-main-menu span {
    font-size: 14px;
    font-weight: bold;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.margin-left-7 {
    margin-left: 7px !important;
}

.font-weight-responsive-menu span {
    font-weight: 700;
}

.chart-loader {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calander-image {
    width: 23px;
    position: absolute;
    right: 35px;
    top: 10px;
}

/* .DateInput::after {
    content: ' ';
    background: transparent;
    display: inline-block;
    width: 35px;
    height: 30px;
    margin: 8px -34px;
    position: absolute;
    background-size: 26px 28px;
    background-repeat: no-repeat;
} */

/* Responsive menu report page start */
.menu-responsive {
    display: none;
}

.chart-grid-show {
    display: block;
}

@media only screen and (max-width: 767px) {
    .menu-responsive {
        display: block;
    }

    .sidebar {
        display: none;
    }

    .clientDashboardGrid .dashboardMainView {
        width: 100%;
        padding-left: 0px;
    }

    .chart-grid-show {
        display: none !important;
    }

    .DateRangePicker {
        display: block !important;
    }

    .DateRangePickerInput {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .calander-image {
        width: 23px;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .full-width-calander-responsive {
        width: 100%;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .menu-responsive {
        display: block;
    }

    .sidebar {
        display: none;
    }

    .clientDashboardGrid .dashboardMainView {
        width: 100%;
        padding-left: 0px;
    }
}

.companyDesc {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}

.companyDesc span {
    font-size: 14px;
    color: #8598a0;
    font-weight: 500;
    padding-left: 30px;
    display: block;
}

.datePickerIcon {
    position: absolute !important;
    right: 10px;
}

.rangepicker {
    position: relative;
}

.PlanntHead {
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 600px) {
        justify-content: flex-start;
    }
}

.CircularProgress {
    position: absolute;
    top: 300px;
}

.clientDashboard .pageTitle {
    // margin-bottom: 0px !important;
}

.clientDashboard .MuiGrid-align-items-xs-flex-start {
    align-items: center;
}

.reportTitle {
    color: #374aa8;
}

.negetiveNumber {
    color: red !important;
}

.AlreadyAdded {
    background-color: #ffffe0 !important;
}

.dashboard-btn-report {
    span {
        line-height: normal;
    }
}

.daterange-main {
    margin-left: 5px;

    @media only screen and (max-device-width: 1199px) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5px;
    }

    @media only screen and (max-width: 600px) {
        justify-content: flex-start;
    }

    svg {
        @media only screen and (max-width: 370px) {
            height: 16px;
            width: 16px;
        }
    }

    .DateInput {
        @media only screen and (max-width: 370px) {
            width: 45%;
        }
    }
}

.rangepicker-dots {
    @media only screen and (max-device-width: 1199px) {
        flex-wrap: nowrap;
    }

    .daterange-main {
        @media only screen and (max-device-width: 1199px) {
            width: auto;
        }
    }
}

.chart-filter-col {
    .MuiPaper-root {
        width: 60px !important;
    }

    &-display {
        img {
            width: 28px;
        }
    }
}

.front-menu {
    svg {
        display: none;

        @media only screen and (max-width: 1279px) {
            display: block;
        }
    }
}

.clientDashboard table th.bordarDark,
.clientDashboard table td.bordarDark {
    border-right-color: darkslateblue;
    border-right-width: 2px;
}

.no-chart-data {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-chart-data a {
    font-size: 18;
    font-weight: bold;
    color: #000;
}

.dashboardIcon span {
    margin: 0 3px;
    display: inline-flex;
    cursor: pointer;
}

.dashboardIcon {
    margin-right: 15px;
    margin-top: 8px;
}

.dashboardTool {
    display: flex;
    justify-content: flex-end;
}

.dashboardIcon img {
    max-height: 32px;
}

.dashboardIcon span.dashboardActive {
    border: 1px solid #928787;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
    display: inline-flex;
}

.dashboardIcon span:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
}

.marge_column_plant_company span {
    color: darkslateblue !important;
    font-style: italic;
    margin-left: 2px;
}

.percentage-switch {
    margin-right: 0 !important;
    margin-left: 0px !important;

    @media screen and (max-width: 480px) {
        width: 100%;
        justify-content: flex-end;
    }
}

.vertical-align-top {
    vertical-align: top !important;
}

.vertical-align-top .TableCheckbox {
    padding: 0px 9px 9px 9px !important;
}

.import-export-btn {
    .MuiButton-startIcon {
        margin-right: 4px !important;
    }

    svg {
        width: 1.2em !important;
        height: 1.2em !important;
    }
}

// .container {
//     cursor: pointer;
//     // margin: 1rem auto;
// }

.dropzone {
    flex: 1;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
}

.grid-item {
    padding: 10px;
    touch-action: none;
    width: 100%;
}

.MuiBox-root .grid-item>div {
    height: 100%;
}

.MuiBox-root .grid-item>div {
    height: 100%;
}

.verticual-button {
    button {
        margin-right: -20px;
    }
}

.parent-chart-box-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 24px;
}

.parent-chart-box-right {
    width: 50%;

    @media screen and (max-width: 1199px) {
        width: 100%;

        .PlanntHead {
            justify-content: flex-start;
        }
    }
}

// .parent-chart-box-center {
//     width: 50%;
//     @media screen and (max-width: 1199px){
//         width: 100%;
//         order:3;
//         .PlanntHead {
//             justify-content: flex-start;
//         }
//     }
// }

.parent-chart-box-left {
    width: 100%;

    @media screen and (max-width: 1199px) {
        width: 100%;
    }
}

/* .chart-dashbaord-main {
    width: 100%;
    margin: 5px 0px 5px 0px;
    display: none;
}

.chart-dashbaord-main-left {
    float: left;
    width: 50%;
}

.chart-dashbaord-main-right {
    float: right;
    width: 50%;
    text-align: right;
} */

.fontStyleDashboardPdf {
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
}

.display-item-end {
    float: right;
}

.report-name {
    display: flex;
    flex-wrap: wrap;
}

/* .dashboard-card {
    height: 100% !important;
} */
.dashboard-card .MuiCardHeader-action {
    margin: 0 !important;
}

.dashboard-card-header span {
    padding: 5px !important;
}

.SelectUserCard {
    // width: 100% !important;
    /* display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex; */
}

.SelectUserCardContent {
    padding: 0 !important;
}

.selectAdminUsers {
    // width: 60% !important;
}

.bulk-delete {
    background-color: "#3F51B5";

    &:hover,
    &.active {
        background-color: #1a237e;
    }
}

.selectCompanyBtn {
    background-image: linear-gradient(0deg, #374aa8 0%, #1477c2 100%) !important;
    // margin-right: 0 !important;
}

.backToLogin {
    height: 38px !important;
}

.otherTable:last-child {
    padding-bottom: 0 !important;
}

.SelectUser-content-3 {
    padding: 10 !important;
}

.CompanyHeading {
    padding-left: 0 !important;
}

.restrcitedTableCell .MuiTableSortLabel-iconDirectionAsc,
.restrcitedTableCell .MuiTableSortLabel-iconDirectionDesc {
    display: none;
    opacity: 0;
}

.tableCompanyContentBtn {
    padding-right: 0 !important;
}

.tableCompanyUserContentBtn {
    width: 100%;
    padding-left: 0 !important;
}

.tableCompanyUserContentBtn .withparentDiv button {
    float: right;
}

.withOutloginTableHeader {
    margin-bottom: 0 !important;
}

.tableOtherData {
    width: 10% !important;
}

.MuiFormHelperText-filled {
    color: red !important;
}

.minWidthtableHeader {
    .TableCell {
        @media only screen and (max-device-width: 768px) {
            min-width: 100px !important;
        }
    }

    .fixedWidth {
        @media only screen and (max-device-width: 768px) {
            min-width: 50px !important;
        }
    }
}

.cursorDefault span {
    cursor: default !important;
}

table.DTFC_Cloned.MuiTable-root.dataTable.no-footer.order-column.row-border.stripe {
    table-layout: fixed !important;
}

@media only screen and (max-device-width: 768px) {
    .MainPageRoot {
        padding-top: 47px;
        height: auto !important;

        .topBar {
            position: fixed !important;
        }
    }
}

.export-chart-loader {
    display: flex;
    align-items: center;
    margin-right: 75px;
}

.no-data-found {
    padding: 10px 0;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
}

.clientDashboard .clientDashboardGrid table th:first-child,
.document-sections table th:first-child,
.clientDashboard .clientDashboardGrid table .bookedRow {
    width: 18px;
}

.selectedData {
    font-size: 20px;
}

.no-chart {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

.page-loader-Div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-loader-Div .page-loader {
    margin: 35px 0px !important;
}

.remaingCount {
    /*width: 160px; */
    margin: 39px 0 0 45px;
    text-align: center;
    display: block;
    color: #374aa8;
    font-weight: 700;
}

.word-wrap-remove {
    word-break: normal !important;
}

@media only screen and (max-device-width: 968px) {
    .min-width-50 {
        min-width: 50px !important;
    }
}

.chart-loader-div {
    // width: 100% !important;
}

.dash-chart-loader {
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #3f51b5;
    font-weight: 800;
}

.copyBtn {
    width: auto !important;
    padding: 6px 10px !important;
    word-break: break-word !important;

    @media (min-width: 768px) and (max-width: 992px) {
        padding: 6px 11px !important;
        word-break: break-word !important;
    }

    @media (max-width: 767px) {
        padding: 6px 8px !important;
        word-break: break-word !important;
    }
}

.easy-edit-wrapper {
    word-break: break-word;
}

// table.dataTable tbody .plant-profile-fixed-td {
//     white-space: nowrap !important;
// }
.Link {
    color: #3f51b5 !important;
}

// .sticky-table-td { white-space: nowrap; padding-left: 5px !important};
.sticky-table-th {
    white-space: nowrap;
    background-color: #d4f2fc;
    text-align: left;
    padding-left: 5px !important;
}

.sticky-table td,
.sticky-table th {
    border: none !important;
    text-align: left !important;
}

div.dataTables_wrapper {
    // width: 800px;
    margin: 0 auto;
}

@media (min-width: 1900px) {
    .easy-edit-wrapper {
        padding-right: 5px !important;
        word-break: break-word !important;
    }
}

.Month-th {
    width: 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
    word-break: break-all !important;
    overflow-wrap: break-word !important;
    padding: 0 !important;
}

.blank_first-th {
    width: 163px !important;
    max-width: 163px !important;
    min-width: 163px !important;
    word-break: break-word !important;
    overflow-wrap: break-word;
}

.blank_sec-th {
    width: 138px !important;
    max-width: 138px !important;
    min-width: 138px !important;
    word-break: break-word !important;
    overflow-wrap: break-word;
}

.addtoDashboard {
    background-image: -moz-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
    background-image: -webkit-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
    background-image: -ms-linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
    background-image: linear-gradient(0deg, rgb(55, 74, 168) 0%, rgb(20, 119, 194) 100%) !important;
    color: #fff !important;
    margin-right: 0 !important;

    width: auto;

    @media only screen and (max-width: 767px) {
        font-size: 10px;
    }
}

.TabPanel {
    margin: 0 -10px;
}

.TabPanel .MuiBox-root {
    padding: 0 !important;
}

.AppBar {
    width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 10px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background-color: #f2fbfe !important;
}

.clientDashboardGrid .MuiTab-textColorPrimary.Mui-selected {
    background-color: #d4f2fa;
}

.clientDashboardGrid .MuiTab-textColorPrimary:hover {
    background-color: #b1e1f1;
}

.mainContentPopup {
    width: 500px !important;
    background-color: #d4f2fa;
}

.file-share-td {
    border-bottom: none !important;
    padding: 16px 16px !important;

    .MuiSvgIcon-root {
        color: #374aa8;
    }
}

.width-5 {
    width: 5%;
}

.sub-document-table {
    padding: 0 !important;
}

.svg-width-file-type-2 {
    width: 20px !important;

    svg {
        width: 1.66rem !important;
    }
}

.externsion_icon {
    width: 60px !important;
    height: 47px !important;
}

.folder-icon-center {
    text-align: center !important;
}

.documents-directories {
    td {
        padding: 5px 16px !important;
    }
}

.documents-directories-head {
    th {
        border-bottom: none !important;
        padding: 8px 16px !important;
    }
}

.iconLink.MuiSvgIcon-root {
    width: 2em !important;
    font-size: 1.8em !important;
}

.docTableCheckbox {
    padding-left: 2px !important;
    padding: 2px !important;
}

.DirectoryNameText,
.DirectoryNameText input {
    height: auto !important;
    width: 230px !important;
}

.DirectoryNameText .MuiInputBase-formControl {
    height: 30px !important;
}

.back-button {
    .MuiSvgIcon-root {
        width: 1.3em !important;
    }
}

.MainPageRoot .dirFilterBtn {
    margin-right: 8px !important;
}

.docTableCheckbox .MuiSvgIcon-root {
    width: 0.9em !important;
    height: 0.9em !important;
}

.customBreadcrumb {
    // margin-bottom: -15px !important;
    margin-top: 15px !important;

    @media only screen and (max-width: 767px) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

.bottomButton {
    margin-top: 17px !important;
}

.documents-directories a {
    color: #000000 !important;
}

.mr-10 {
    margin: 10px 0px !important;
}

.vertical-align-middle {
    svg {
        vertical-align: middle;
    }
}

.document-sections:last-child {
    padding-bottom: 0px !important;
}

// .timeSeriesToolHeader {
//     margin-bottom: 36px !important;
// }
.card-header-background {
    background-color: #d4f2fc;
}

.time-series-tool-drop-down-label {
    display: flex;
    align-items: center;
}

.time-series-chart-button {
    .MuiButton-label {
        padding: 3px;
    }
}

.time-series-add-to-dashboard-button {
    margin-left: 16px !important;

    // .MuiButton-label {
    //     padding: 3px;
    // }
}

@media only screen and (max-width: 767px) {
    .DirtableHeaderPageHeader {
        width: 100%;
    }

    .DirtableHeaderPageHeader button {
        float: right;
        text-align: right;
        margin-top: 14px !important;
    }

    .back-button {
        padding: 6px 9px !important;
    }
}

.chart-tool-inputs {
    padding: 10px !important;
}

@media only screen and (max-width: 992px) {
    .chart-dropdown-title {
        margin-bottom: 10px !important;
    }
}

.chartPlantList {
    padding-left: 40px;
    padding: 0 110px;
    display: flex;
    justify-content: space-between;
}

.axis_desc {
    padding-bottom: 10px;
}

.grayColor {
    width: 100%;
    height: 2px;
    background-color: steelblue;
    display: block;
}

.numberUnits {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5px;
}

.numberUnits span {
    line-height: 1;
}

.numberFlags {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;
}

.numberFlags span {
    line-height: 0.8;
    width: 10px;
    position: absolute;
    top: 0;
}

.numberFlags span img {
    width: 100%;
}

.dayRange {
    padding: 5px 10px;
}

.numberFlags .rightFlag {
    margin-top: 2px;
}

.leftLegend {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 5px 24px;
    flex-direction: row;
}

.PlantTitle {
    text-align: center;
}

.contactSettingPadding {
    padding: 0 !important;
}

.clientDashboard .contactSettingPadding {
    width: 100%;
}

.other-setting-checkbox li {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.pd-5 {
    width: auto;
    overflow: hidden;
    font-size: 1rem;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 6px;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 6px;
}

.m-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mrb-15 {
    margin-bottom: 15px !important;
}

.mrb-10 {
    margin-bottom: 10px !important;
}

.mrb-6 {
    margin-bottom: 6px !important;
}

.mrb-20 {
    margin-bottom: 20px;
}

.mrb-25 {
    margin-bottom: 25px;
}

.mrr-6 {
    margin-right: 6px !important;
}

.mrl-10 {
    margin-left: 10px;
}

.trail-data-add-padding {
    padding: 0px !important;
}

.trail-data-add-title {
    padding: 10px 0 0 0;
    margin-bottom: 10px !important;
}

.text-center {
    text-align: center;
}

.text-underline {
    text-decoration: underline;
}

.bolder-font {
    font-weight: bold;
}

.ul-list .li-list {
    margin: 5px 35px;
    color: #263238;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.05px;

}

.time-series-tool-data-list {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.time-series-tool-data-list .categoryLink {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.time-series-tool-data-list .categoryLinkActive {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.chart-tool-right-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.height-100 {
    height: 100%;
}

.scroll-time-series-chart-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding-right: 15px;

    @media (max-width: 1023px) {
        padding-right: 0;
        height: auto;
    }

    @media (max-width: 1170px) {
        height: auto;
    }

    .leftSidebar {
        width: 100%;

        @media (max-width: 481px) {}
    }
}

.scroll-time-series-chart-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    padding-left: 15px;

    @media (max-width: 1023px) {
        padding-left: 0;
        height: auto;
        padding-bottom: 30px;
    }
}

.stripe.row-border.report-page td:nth-child(2) {
    width: 390px;

    @media (min-width: 1400px) and (max-width: 1450px) {
        width: 372px;
    }

    @media (min-width: 1280px) and (max-width: 1370px) {
        width: 307px;
    }

    @media (min-width: 993px) and (max-width: 1023px) {
        width: 205px;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        width: 191px;
    }

    @media only screen and (max-width: 767px) {
        width: 260px;
    }
}

.stripe.row-border.report-page td:nth-child(3) {
    width: 78px;

    @media (min-width: 1400px) and (max-width: 1450px) {
        width: 77px;
    }

    @media (min-width: 1280px) and (max-width: 1370px) {
        width: 70px;
    }

    @media (min-width: 993px) and (max-width: 1023px) {
        width: 59px;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        width: 57px;
    }

    @media only screen and (max-width: 767px) {
        width: 63px;
    }
}

// .topbarRoot .MuiToolbar-regular .navigation .navigationRoot .subNavButtom .MuiList-padding .navItem .navButtonLeaf:hover {
//     background-color: #d4f2fc;
// }
.DefaultFrontLayout-topBar-2 {
    z-index: 99 !important;
}

.CharttableHeaderPageHeader {
    margin-bottom: 32px;
}

.reportViewSelect {
    height: 46px !important;
}

.reportViewOption {
    width: 20% !important;
    padding-right: 5px !important;

    @media (max-width: 481px) {
        width: 100% !important;
        margin-bottom: 10px !important;
        padding-right: 0 !important;
    }
}

.report-header {
    @media (max-width: 481px) {
        margin: 0;
    }
}

.rangepicker {
    ._class-dateFilterBtn-45 {
        @media (max-width: 481px) {
            width: calc(100% - 25px) !important;
        }
    }
}

// .add-to-dashboard-chart .activebtn {
//     margin-right: 15px !important;
// }

.tearmsConditionCard {
    width: 1200px !important;
    padding: 1.5rem;
}

.termsActionButton {
    width: 1200px !important;

    @media (max-width: 1023px) {
        width: 100% !important;
    }

    // text-align: center;
}

// toolChart
.ClientPageRoot {
    height: 100% !important;
    // overflow: inherit !important;
}

.ClientPageRoot .clientDashboard-report .tableHeaderPageHeader {
    margin-bottom: 12px !important;
}

.ClientPageRoot .clientDashboard-report .pageTitle {
    padding: 10px 0 0 0 !important;
    // margin-bottom: 24px !important;
}

.toolChart {
    height: 100%;
    align-items: flex-start;
    align-content: flex-start;

    @media (max-width: 767px) {
        height: auto;
    }

    .tableHeaderPageHeader,
    .clientDashboardGrid {
        // max-height: calc(100vh - 303px);
        align-self: stretch;

        @media (max-width: 767px) {
            height: auto !important;
            max-height: 500px;
        }

        @media (max-width: 481px) {
            width: 100%;
        }
    }

    .clientDashboardGrid {
        width: auto;

        @media (max-width: 1023px) {
            padding-bottom: 10px;
        }

        @media (max-width: 481px) {
            width: 100%;
        }

        .leftSidebar {
            width: 100%;

            @media (max-width: 481px) {}
        }

        .rightSidebar {
            width: 100%;

            @media (max-width: 481px) {}
        }

        .sidebar {
            display: block;
            flex: 1 1 auto;
            overflow: auto;
            width: 100%;

            @media (max-width: 1023px) {
                max-height: 500px;
            }

            .accordianTitle {
                position: sticky;
                top: 0;
                z-index: 1;
            }
        }

        .rightSidebarMenu {
            display: block;
            flex: 1 1 auto;
            overflow: auto;
            float: right;
            width: 100%;

            @media (max-width: 1023px) {
                max-height: 500px;
            }

            .accordianTitle {
                position: sticky;
                top: 0;
                z-index: 1;
            }
        }

        .menu-responsive {
            display: none;
        }

        .frmEdit {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    .tableHeaderPageHeader {
        align-items: flex-start;
        width: 100%;

        @media (max-width: 767px) {
            margin-bottom: 0 !important;
        }

        @media (max-width: 481px) {
            max-height: none;
            width: 100%;
            // height: 80vh !important;
            margin: 15px 0 0 0;
        }

        .time-series-chart-div {

            // height: 100%;
            @media (max-width: 1023px) {
                margin-top: 15px;
                height: auto;
            }

            .cardMainContent {
                // height: 100%;

                @media (min-width: 1024px) and (max-width: 1200px) {
                    height: calc(100% - 87px);
                }

                @media (max-width: 1023px) {
                    height: 500px;
                    margin-bottom: 15px;
                }

                .MuiCardContent-root {
                    // height: 100%;

                    #chartdiv {
                        // height: 100%;

                        @media (max-width: 767px) {
                            height: calc(100% - 30px) !important;
                        }
                    }
                }

                .chartPlantList {
                    padding: 0;
                    font-size: 14px;
                    margin-bottom: 10px;
                }


                .scale_1366 {
                    transform-origin: top;
                    padding: 20px !important;
                }

                .scale_1920 {
                    transform-origin: top;
                    padding: 20px !important;
                }

                .scale_4k {
                    transform-origin: top;
                    padding: 20px !important;
                }
            }

        }

        .chartGridContent {
            // width: 100%;
            // max-width: 100%;
            // flex-basis: 100%;
            height: 100%;
            overflow: auto;

            @media (max-width: 1023px) {
                height: auto;
            }

            .cardMainContent {
                height: 100%;

                @media (max-width: 1023px) {
                    height: auto;
                }

                .MuiCardContent-root {
                    height: 100%;

                    #chartdiv {
                        width: 100%;
                        height: 100% !important;
                    }
                }
            }
        }
    }

    .basicDetails {
        padding: 14px;
    }

    .GroupDropdown {
        padding-bottom: 28px;
    }

    // .timeSeriesToolHeader {
    //     padding-bottom: 20px;
    // }
    .leftSidebar {
        // width: 255px;
        width: 100%;
        background: #fff;
        border: 1px solid #000;
        margin-bottom: 11px !important;
        float: left;
    }

    .rightSidebar {
        width: 255px;
        background: #fff;
        border: 1px solid #000;
        margin-bottom: 11px !important;
        float: right;
    }
}

.docRoot {
    padding: 0 !important;
}

.tableDataName {
    max-width: 300px !important;
    min-width: 200px !important;
}

.visualCols {
    min-width: 200px !important;
}

.tableMain {
    .ClientGroupDocumentsTool-tableHeaderPageHeader-121 {
        margin-bottom: 9px;
    }
}

.ReportPlantDiv {
    display: flex;
    align-items: center;
}

.userFrmEdit {
    margin-top: 20px !important;
}

.GroupTable .TableBody .TableCell {
    word-break: normal !important;
}

.page-footer {
    font-size: 12px;
    font-weight: 300;
    padding: 17px 0;
    background-color: #374aa8;
    color: #ffffff;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.overflow-y {
    overflow-y: scroll !important;
}

.terms-condition-button {
    margin-top: 10px;

    @media only screen and (max-width: 1200px) {
        margin-right: auto;
    }
}

.footer-main {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
}

.reportDivider,
.reportTab {
    width: 100%;
}

.clientDoctool {
    width: 100%;
}

.font-weight-normal {
    font-weight: 100;
}

.loadFrmCardContent {
    padding: 8px 0 0 0 !important;
}

.loadFrmCard .formDivider {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

.loadFrmCard .btnAction {
    padding-left: 15px !important;
}

.border-top {
    border-top: 1px solid #bbbbbb;
}

#example_wrapper table tr {
    background-color: #FFFFFF;

    td {
        // padding: 16px !important;
    }
}

.profile-data-right {
    display: flex;
    align-items: center;
    float: right;

    @media only screen and (max-width: 567px) {
        float: none;
    }
}

.mb-16 {
    margin-bottom: 16px !important;
}

.plantProfileSidebar {
    display: block !important;
}

@media only screen and (max-width: 1030px) {
    .plantprofileParent {
        width: 100%;
        flex-direction: column;
    }

    .DashboardPlantprofileParent,
    .plantProfileSidebar {
        width: 100% !important;
    }

    .toolChart {
        height: auto !important;

        .tableHeaderPageHeader,
        .clientDashboardGrid {
            height: 100% !important;
            max-height: inherit;
        }
    }
}

.DashboardPlantprofileParent {
    width: 100% !important;
}

@media only screen and (max-width: 767px) {
    #chart-list-component .MuiCardContent-root {
        padding: 0 !important;
    }
}

.docTool {
    height: auto !important;
}

.toolChartReport {

    .rightSidebarMenu,
    .chartsidebar {
        .MuiListItem-button {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }
    }

    .accordianTitle {
        font-size: 14px;
        margin-bottom: 2px !important;
        padding: 8px !important;
    }

    .categoryLink,
    .categoryLinkActive {
        span {
            font-size: 12px !important;
        }
    }

    .MuiOutlinedInput-input {
        padding: 10px 14px;
        font-size: 12px;
    }
}

.privatePolicy {
    padding-right: 10px;
}

.alignCenter {
    text-align: center;
}

.tearmsConditionCard {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 50px;

    .subtitle1 {
        line-height: 1.5;
        font-size: 16px;
    }
}

.tableSelectedRow {
    padding: 24px !important;
}

.plant-profile-data-row td {
    padding: 16px !important;
}

.text-center {
    text-align: center !important;
}

.ReportHeadingGrid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.ReportOptionMenu {
    width: 100%;
    padding: 10px;
    float: right;
    text-align: end;
    position: relative;
    top: 10px;
}

.ReportMenu {
    @media screen and (max-width: 600px) {
        max-width: 600px;
        width: 100%;
    }
    padding-left: 86rem;

    .topnav {
        display: flex;
        overflow: auto;
    }

    .topnav a {
        float: left;
        display: block;
        color: #263238;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
        border-bottom: 2px solid transparent;
        transition: 0.2s;
        white-space: nowrap;
    }

    .active {
        color: #374aa8 !important;
        border-bottom-color: #374aa8 !important;
    }

    .topnav .icon {
        display: none;
    }

    .dropdown {
        float: left;
    }

    .dropdown .dropbtn {
        font-size: 17px;
        border: none;
        outline: none;
        color: #263238;
        padding: 14px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
        border-bottom: 2px solid transparent;
        white-space: nowrap;
        transition: 0.2s;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        float: none;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
    }

    .topnav>a:hover,
    .dropdown:hover>.dropbtn {
        color: #374aa8;
        border-bottom-color: #374aa8;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
        color: black;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    @media screen and (max-width: 600px) {
        .topnav a.icon {
            float: right;
            display: block;
        }
    }

    @media screen and (max-width: 600px) {
        .topnav.responsive {
            position: relative;
        }

        .topnav.responsive .icon {
            position: absolute;
            right: 0;
            top: 0;
        }

        .topnav.responsive a {
            float: none;
            display: block;
            text-align: left;
        }

        .topnav.responsive .dropdown {
            float: none;
        }

        .topnav.responsive .dropdown-content {
            position: relative;
        }

        .topnav.responsive .dropdown .dropbtn {
            display: block;
            width: 100%;
            text-align: left;
        }
    }
}

.ReportDocumentpageTitle {
    padding-top: 24px !important;
    margin-bottom: 0 !important;
    padding-left: 24px !important;

    @media only screen and (max-width: 767px) {
        padding-top: 15px !important;
        padding-left: 15px !important;
    }

}

.card-scroll {
    overflow: auto !important;
    height: calc(100vh - 374px) !important;
}

.formControl {
    width: 100%;
}

.trialRoot {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.TrialDataHeader {
    // display: flex;
    justify-content: flex-end !important;
}

.select_drop {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 599px) {
        justify-content: flex-start;
    }
}

.group_drop {
    width: 250px !important;

    @media (max-width: 599px) {
        width: 138px !important;
    }

    flex: 0 1 auto;
    margin-right: 10px;
}

.show_filter_btn {
    width: 155px !important;
}

.select_drop .select_lb {
    top: -10px;
    left: 13px;

    &.MuiFormLabel-filled {
        top: -4px;
        left: 14px;
    }

    &.Mui-focused {
        left: 15px;
    }
}

.height-40 {
    height: 40px;
}

.bordarBlackDark {
    border-right-color: black !important;
    border-right-width: 2px !important;
}

/* Tooltip style */

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px solid #374aa8;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #f2fbfe;
    color: #546e7a;
    text-align: center;
    border-radius: 6px;
    padding: 10px !important;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #546e7a transparent transparent transparent;
    transform: rotate(180deg);
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

@media (max-width:767px) {
    .tooltip .tooltiptext {
        width: 180px;
        left: 0;
        transform: none;
        font-size: 14px;
    }

    .tooltip .tooltiptext::after {
        left: 30px;
    }
}

.tableFilterFormGroup .MuiFormControl-root {
    margin: 0 !important;
}

.scales {
    scale: 0.75;
    font-size: 1.50em;
    margin-top: -30px;
}

.custom-legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.legend-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
}

.childList {
    display: flex;
    flex-direction: column;
    align-items: center;
}